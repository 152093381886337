import React from 'react'
import Layout from '../../components/layout'
import { WordRapCreator } from '../../components/ChallengeCreators'

const WordRapCreatorPage = ({ challengeId }) => (
  <Layout>
    <WordRapCreator challengeId={challengeId} />
  </Layout>
)

export default WordRapCreatorPage
